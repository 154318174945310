(function ($) {
  Drupal.behaviors.productGrid = {
    attach: function (context) {
      var $grids = $('.product-grid', context);
      var $carouselGrids = $grids.filter('.product-grid--carousel');
      var $carousels = $('.js-product-carousel', $carouselGrids);
      var sortPlaceholder = '<div class="js-product-grid-sort-placeholder" />';
      var hasQuickshop = $grids.hasClass('product-grid--quickshop');
      var $wrapper = $('.product-grid-wrapper', context);

      // For any products set to OOS that are also in the 'ticker', hide them
      // Per CX-219, the brand says these are 'limited life' and need to not be displayed
      $(document).on('ticker_product_oos', '.js-product', function (event, passedProdId) {
        var $prodSlctr = $("[data-product-id='" + passedProdId + "']");

        if ($prodSlctr && $prodSlctr.length > 0) {
          $prodSlctr.addClass('hidden');
          $grids.trigger('grid.reflow');
        }
      });

      // Loop through and init the carousels.
      // Carousels might contain variations, so dynamically change the settings before constructing slick
      $carousels.each(function () {
        var arrowsDiv = $(this).parent().find('.carousel-controls');
        var dotsDiv = $(this).parent().find('.carousel-dots');
        var settings = {
          appendArrows: arrowsDiv,
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 3,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                appendDots: dotsDiv,
                arrows: false,
                dots: true,
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        };
        // slidesToShow override
        var slidesToShowCount = $(this).data('slides-to-show');

        if (slidesToShowCount) {
          settings.slidesToShow = slidesToShowCount;
        }

        // Init this carousel with our settings
        $(this).slick(settings);

        // On before slide change
        $(this).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
          // Remove quickshop:
          if (hasQuickshop && Drupal.behaviors.quickshop) {
            $('.js-quickshop', $wrapper).remove();
          }
        });
      });
    }
  };
})(jQuery);
